import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
// import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/utils/takeOverKit2025.module.scss';

import TextEditorRender from 'components/utils/TextEditorRender';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import Modal from 'components/utils/Modal';
import leftimage from '../../images/takeover/kit2025/kit2025-thumbnail-portrait.jpg';
import offsetimage from '../../images/takeover/kit2025/kit2025-offset.png';
import landscapeimage from '../../images/takeover/kit2025/kit2025.jpg';
import playicon from '../../images/takeover/kit2025/play-icon.png';

const TakeOver = ({ takeoverConfig }) => {

    const stayLiveUrl = takeoverConfig.youtubeVideoId ? takeoverConfig.youtubeVideoId : null;

  const jerseyLink = takeoverConfig.jerseyUrl ? takeoverConfig.jerseyUrl : null;
  const ctaLink = takeoverConfig.ctaUrl ? takeoverConfig.ctaUrl : null;

  const jerseyLinkText = takeoverConfig.jerseyText ? takeoverConfig.jerseyText : 'Köp tröjan';
  const ctaLinkText = takeoverConfig.ctaText ? takeoverConfig.ctaText : 'Läs mer';

  const [isTakeOverActive, toggleTakeOver] = useState(true);
    const [videoPlaying, setVideoPlaying] = useState(false);

  function fakeNavigation() {
    toggleTakeOver(false);
    document.getElementById("seasonalTakeover").remove();
  }

  return (
    <div id="seasonalTakeover" className={style.takeOver__wrapper}>
      {isTakeOverActive &&
        <Helmet>
            <body data-modal-open="true" />
        </Helmet>
      }
      <div className={style.takeOver__container}>
        <div className={style.takeOver__content}>
          <button type="button" className={style.takeOver__close} aria-label='Stäng' data-testid="close-button" onClick={() => fakeNavigation(false)}>
            <svg viewBox="0 0 36 36" data-testid="close-icon" fill="#000000" width="32" height="32"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"/></svg>
          </button>
          <div className={style.takeOver__left} >
            <img src={leftimage} alt="Framåt mot härliga tider" />
            {stayLiveUrl && (
                <button
                    aria-label="play video"
                    type="button"
                    className={style.takeOver__columnVideoClicker}
                    onClick={() => {
                        setVideoPlaying(true);
                    }}
                >
                    <img src={playicon}
                        alt="play icon"
                        className={style.takeOver__playIcon}
                    />
                </button>
            )}
              <div className={style.takeOver__columnJerseyCta}>
              {jerseyLink && (
                <a className={`${style.takeOver__columnCta} ${style.takeOver__showDesktop}`} target="_blank" rel="noreferrer" href={jerseyLink}>{jerseyLinkText}</a>
              )}
              </div>
          </div>

          <div className={style.takeOver__right}>
            <div className={style.takeOver__rightContent}>
                {takeoverConfig.headline &&
                    <h2>{takeoverConfig.headline}</h2>
                }
                {takeoverConfig._rawPreamble && <TextEditorRender content={takeoverConfig._rawPreamble} />}
                <div className={style.takeOver__ctaCollection}>
                    <div>
                        {stayLiveUrl && (
                            <button
                                aria-label="play video"
                                type="button"
                                className={style.takeOver__columnVideoClicker}
                                onClick={() => {
                                    setVideoPlaying(true);
                                }}
                            >
                                <img src={playicon}
                                    alt="play icon"
                                    className={style.takeOver__playIcon}
                                />
                            </button>
                        )}
                        <img src={landscapeimage} alt="Framåt mot härliga tider" />
                    </div>
                    {jerseyLink && (
                        <a className={style.takeOver__columnCta} target="_blank" rel="noreferrer" href={jerseyLink}>{jerseyLinkText}</a>
                    )}
                    {ctaLink && (
                        <a className={`${style.takeOver__columnCta}`} href={ctaLink}>{ctaLinkText}</a>
                    )}
                </div>
            </div>
            <div className={style.takeOver__offsetContainer}>
                {ctaLink &&
                    <div className={style.takeOver__columnTextCta}>
                        <a className={`${style.takeOver__columnCta} ${style.takeOver__showDesktop}`} href={ctaLink}>{ctaLinkText}</a>
                    </div>
                }
              <div className={`${style.takeOver__offsetImage} ${style.takeOver__showDesktop}`}>
                <img src={offsetimage} alt="Framåt mot härliga tider" />
              </div>
            </div>

          </div>
        </div>
      </div>
      {stayLiveUrl && videoPlaying && (
            <Modal
                className={style.newsItem__videoModal}
                show={videoPlaying}
                onClose={() => setVideoPlaying(false)}
            >
                <EmbedVideoPlayer
                    url={`${stayLiveUrl}?autoplay=1`}
                    title='Matchställ 2025'
                />
            </Modal>
        )}
    </div>
  );
};
export default TakeOver;
