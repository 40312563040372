// import * as React from 'react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
// eslint-disable-next-line
import { navigate } from 'gatsby';
import Cookie from 'universal-cookie';

import { AuthProvider, useAuth } from 'context/UserContext';
import { defaultLang, LangProvider } from 'context/LangContext';
import { RoutesProvider } from 'context/RoutesContext';
import Header from 'components/header/Header';
// import Header from 'components/header/MegaHeader';
import Footer from 'components/footer/Footer';
import GetPagesRoutes from 'data/queries/getPagesRoutes';
import useSanityConfigGlobal from 'data/queries/useSanityConfigGlobal';
import useRudderStack from 'hooks/useRudderStack';
import TakeOver from 'components/utils/TakeOverKit2025';
// import TakeOver from 'components/utils/TakeOverJersey';
// import TakeOver from 'components/utils/TakeOverBenfica';
// import TakeOver from 'components/utils/TakeOver2024';
import CommonSplash from 'components/misc/Splash';

const Layout = ({ pageContext, children }) => {
    const routes = GetPagesRoutes();
    const { isSignedIn, user } = useAuth();

    const { identify } = useRudderStack();
    const context = Object.keys(pageContext).length !== 0 ? pageContext : false;
    const lang = context ? pageContext.page?.language : defaultLang;
    const seo = context ? pageContext.page?.seo : {};
    const isHomepage = context ? pageContext.page?.content?.type === 'home' : false;
    let requireAuthRedirection = false;

    if (context && context.requireAuth && isSignedIn === false) {
        requireAuthRedirection = true;
        navigate('/login');
    }

    const globalConfig = useSanityConfigGlobal();
    const [showTakeover, setShowTakeover] = useState(false);
    const [isAppCall, setIsAppCall] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const cookie = new Cookie();

        if( globalConfig.seasonalTakeoverConfig.enabled ) {
            if( !cookie.get('takeoverShown') ) {
                cookie.set('takeoverShown', 'true', { path: '/' });
                setShowTakeover(true);
            }
        }
        if( urlParams.get('takeover') === 'active' ) {
            setShowTakeover(true);
        }

        if( urlParams.get('isAppCall') === 'true' ) {
            setIsAppCall(true);
        }

    }, [showTakeover, globalConfig]);
    useEffect(() => {
        if( isSignedIn ) {
            identify(user.sub, {
                email: user.attributes.email,
                name: user.name,
            });
        } else {
          // For anonymous users, RudderStack automatically assigns an anonymousId
        }
    }, [isSignedIn, user, identify]);
    return (
        <AuthProvider>
            <LangProvider pageContextLang={lang}>
                <RoutesProvider initRoutes={routes}>
                    {seo && (
                        <MetaTags>
                            <title>{seo.title}</title>
                            <meta name="description" content={seo.description} />
                            <meta name="keywords" content={seo.keywords} />
                            <link rel="canonical" href={seo.canonicalUrl} />
                            {seo.no_index && <meta name="robots" content="noindex" />}
                            {seo.no_follow && <meta name="robots" content="nofollow" />}
                        </MetaTags>
                    )}

                    <Helmet
                        htmlAttributes={{
                            lang: lang?.iso ? lang.iso : defaultLang.iso,
                        }}>

                        {/* OneTrust script */}
                        <script
                            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                            type="text/javascript"
                            data-document-language="true"
                            charSet="UTF-8"
                            data-domain-script={process.env.GATSBY_ONETRUST_SCRIPT_DOMAIN}
                        />
                        <script type="text/javascript">{`${function OptanonWrapper() {}}`}</script>

                        {/* Imbox script */}
                        <script type="text/javascript">
                            {`
                                var _sid = '666';
                                (function(){
                                    var se = document.createElement('script');
                                    se.type = 'text/javascript';
                                    se.async = true;
                                    se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'files.imbox.io/app/dist/initWidget.js';
    
                                    var s = document.getElementsByTagName('script')[0];
                                    s.parentNode.insertBefore(se, s);
                                })();
                            `}
                        </script>
                    </Helmet>
                    {showTakeover && (
                        <TakeOver takeoverConfig={globalConfig.seasonalTakeoverConfig}/>
                    )}
                    {!showTakeover && (
                        <CommonSplash />
                    )}
                    {globalConfig && globalConfig.headerConfig && !isAppCall && (
                        <Header
                            isHomepage={isHomepage}
                            headerConfig={globalConfig.headerConfig}
                            intl={pageContext.intl}
                        />
                    )}

                    {!requireAuthRedirection && (children)}

                    {globalConfig && globalConfig.footerConfig && !isAppCall && (
                        <Footer footerConfig={globalConfig.footerConfig} intl={pageContext.intl} />
                    )}
                </RoutesProvider>
            </LangProvider>
        </AuthProvider>
    );
};

export default Layout;
